import React from 'react';
import PropTypes from 'prop-types';

const Service = ({ children, Icon, id, primary, title }) => {
  return (
    <div
      id={id}
      className="flex flex-wrap sm:flex-no-wrap mb-4 mt-32 mx-auto sm:p-4 relative w-full sm:w-1/2 lg:w-1/3"
    >
      <div
        className={`absolute bg-${primary} flex inset-x-0 items-center justify-center m-auto p-8 rounded-full top-0 up-50 h-40 w-40`}
      >
        <Icon className="fill-current text-white h-full w-full" />
      </div>
      <div className="bg-gray-200 flex flex-col max-w-3xl mx-auto p-8 pt-24 text-center w-full">
        <h3
          className={`font-bold font-display leading-none uppercase text-${primary} text-3xl w-full`}
        >
          {title}
        </h3>
        <div className="mt-auto">{children}</div>
      </div>
    </div>
  );
};

Service.propTypes = {
  children: PropTypes.node.isRequired,
  Icon: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  primary: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Service;
