import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

const About = ({ primary = `blue-600`, secondary = `blue-500` }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "about.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 544, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <div id="about" className="max-w-6xl mx-auto px-4 py-12 w-full">
      <div className="flex flex-wrap -mx-4 pb-12 md:pb-0">
        <div className="flex flex-col mb-4 md:pb-12 px-8 w-full md:w-1/2">
          <h2
            className={`font-bold font-display leading-none mb-4 md:mt-4 text-4xl text-${primary} uppercase`}
          >
            About Us
          </h2>
          <p>
            As one of the areas most trusted businesses, Custom Metal Roofing
            has successfully serviced numerous homes across NSW, from the Mid
            North Coast, down to Sydney and the South Coast.
          </p>
          <p className="mt-4">
            Owner-operator, Mark Dunkley, is a qualified roof plumber with more
            than twenty years of experience in the industry. With the assistance
            of his wife, Claire, Mark began operating as Custom Metal Roofing
            and has since supported the development of numerous local homes. He
            is considered to be a local expert in roofing and building projects.
          </p>
          <p
            className={`font-semibold mt-auto pt-4 text-${primary} hover:text-${secondary} hover:underline`}
          >
            <Link to="/about">Read more</Link>
          </p>
        </div>
        <div className="mb-4 relative w-full md:w-1/2">
          <div className="md:absolute md:inset-0 mx-8 md:ml-0 md:overflow-hidden">
            <Image
              style={{ height: `100%` }}
              fluid={data.file.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

About.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
};

export default About;
