import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import CallToActionHero from '../components/CallToAction/Hero';
import About from '../components/About';
import CallToActionServices from '../components/CallToAction/Services';
import OurServices from '../components/OurServices';
import CallToActionProjects from '../components/CallToAction/Projects';
import OurProjects from '../components/OurProjects';
import SeeMoreProjects from '../components/SeeMoreProjects';
import CallToActionContact from '../components/CallToAction/Contact';
import Contact from '../components/Contact';

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Layout>
      <Hero />
      <CallToActionHero />
      <About />
      <CallToActionServices />
      <OurServices primary="pink-600" />
      <CallToActionProjects />
      <OurProjects />
      <SeeMoreProjects />
      <CallToActionContact />
      <Contact />
    </Layout>
  </>
);

export default IndexPage;
