import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Service from './Service';
import NewRoofsIcon from './icons/NewRoofsIcon';
import WallCladdingIcon from './icons/WallCladdingIcon';
import GuttersIcon from './icons/GuttersIcon';
import GutterGuardIcon from './icons/GutterGuardIcon';
import FasciaIcon from './icons/FasciaIcon';
import DownpipesIcon from './icons/DownpipesIcon';
import SkylightsIcon from './icons/SkylightsIcon';

const OurServices = ({ primary = `gray-500` }) => {
  return (
    <div className="max-w-6xl mx-auto px-8 py-12 w-full">
      <h2
        className={`font-bold font-display leading-none mb-8 text-4xl text-center text-${primary} uppercase`}
      >
        Our Services
      </h2>
      <div className="flex flex-wrap sm:-mx-4">
        <Service
          title="New Roofs"
          Icon={NewRoofsIcon}
          id="new-roofs"
          primary={primary}
        >
          <Link
            to="/services/#new-roofs"
            className={`font-semibold inline-block mt-4 hover:text-${primary} hover:underline`}
          >
            Read more
          </Link>
        </Service>
        <Service
          title="Wall Cladding"
          Icon={WallCladdingIcon}
          id="wall-cladding"
          primary={primary}
        >
          <Link
            to="/services/#wall-cladding"
            className={`font-semibold inline-block mt-4 hover:text-${primary} hover:underline`}
          >
            Read more
          </Link>
        </Service>
        <Service
          title="Gutters"
          Icon={GuttersIcon}
          id="gutters"
          primary={primary}
        >
          <Link
            to="/services/#gutters"
            className={`font-semibold inline-block mt-4 hover:text-${primary} hover:underline`}
          >
            Read more
          </Link>
        </Service>
        <Service
          title="Gutter Guard"
          Icon={GutterGuardIcon}
          id="gutter-guard"
          primary={primary}
        >
          <Link
            to="/services/#gutter-guard"
            className={`font-semibold inline-block mt-4 hover:text-${primary} hover:underline`}
          >
            Read more
          </Link>
        </Service>
        <Service title="Fascia" Icon={FasciaIcon} id="fascia" primary={primary}>
          <Link
            to="/services/#fascia"
            className={`font-semibold inline-block mt-4 hover:text-${primary} hover:underline`}
          >
            Read more
          </Link>
        </Service>
        <Service
          title="Downpipes"
          Icon={DownpipesIcon}
          id="downpipes"
          primary={primary}
        >
          <Link
            to="/services/#downpipes"
            className={`font-semibold inline-block mt-4 hover:text-${primary} hover:underline`}
          >
            Read more
          </Link>
        </Service>
        <Service
          title="Skylights"
          Icon={SkylightsIcon}
          id="skylights"
          primary={primary}
        >
          <Link
            to="/services/#skylights"
            className={`font-semibold inline-block mt-4 hover:text-${primary} hover:underline`}
          >
            Read more
          </Link>
        </Service>
      </div>
    </div>
  );
};

OurServices.propTypes = {
  primary: PropTypes.string,
};

export default OurServices;
