import React from 'react';

const CallToActionHero = () => {
  return (
    <div className="sm:flex -mb-12 relative up-50">
      <p className="cta-left bg-blue-600 p-8 font-bold font-display leading-tight text-white text-xl md:text-2xl lg:text-3xl uppercase">
        <span className="inline-block mr-8 sm:mr-24 whitespace-no-wrap">
          Fully qualified{' '}
        </span>
        <br />
        <span className="inline-block mr-8 sm:mr-24 whitespace-no-wrap">
          roof plumber with{' '}
        </span>
        <br />
        <span className="inline-block mr-8 sm:mr-24 whitespace-no-wrap">
          20 years experience
        </span>
      </p>
    </div>
  );
};

export default CallToActionHero;
