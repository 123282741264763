import React from 'react';

const CallToActionProjects = () => {
  return (
    <div className="relative">
      <div className="sm:flex relative">
        <div className="absolute bg-gray-800 inset-0 my-4 w-full" />
        <p className="cta-projects bg-pink-600 p-8 font-bold leading-tight text-white text-xl md:text-2xl lg:text-3xl uppercase">
          <span className="inline-block mr-8 sm:mr-24 whitespace-no-wrap">
            Specialising{' '}
          </span>
          <br />
          <span className="inline-block mr-8 sm:mr-24 whitespace-no-wrap">
            in all aspects of{' '}
          </span>
          <br />
          <span className="inline-block mr-8 sm:mr-24 whitespace-no-wrap">
            metal roofing
          </span>
        </p>
      </div>
    </div>
  );
};

export default CallToActionProjects;
