import React, { useState } from 'react';
import Lightbox from 'fslightbox-react';

import ImageOne from '../images/carousel/carousel-00001.jpg';
import ImageTwo from '../images/carousel/carousel-00002.jpg';
import ImageThree from '../images/carousel/carousel-00003.jpg';
import ImageFour from '../images/carousel/carousel-00004.jpg';
import ImageFive from '../images/carousel/carousel-00005.jpg';

const slides = [ImageOne, ImageTwo, ImageThree, ImageFour, ImageFive];

const App = () => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  return (
    <div className="flex items-center mb-6 mx-auto px-8">
      <button
        onClick={() => openLightboxOnSlide(1)}
        type="button"
        className="flex h-64 mr-4"
      >
        <img
          src={slides[0]}
          alt=""
          className="h-full object-cover"
          loading="lazy"
        />
      </button>
      <button
        onClick={() => openLightboxOnSlide(2)}
        type="button"
        className="flex h-64 mr-4"
      >
        <img
          src={slides[1]}
          alt=""
          className="h-full object-cover"
          loading="lazy"
        />
      </button>
      <button
        onClick={() => openLightboxOnSlide(3)}
        type="button"
        className="flex h-64 mr-4"
      >
        <img
          src={slides[2]}
          alt=""
          className="h-full object-cover"
          loading="lazy"
        />
      </button>
      <button
        onClick={() => openLightboxOnSlide(4)}
        type="button"
        className="flex h-64 mr-4"
      >
        <img
          src={slides[3]}
          alt=""
          className="h-full object-cover"
          loading="lazy"
        />
      </button>
      <button
        onClick={() => openLightboxOnSlide(5)}
        type="button"
        className="flex h-64"
      >
        <img
          src={slides[4]}
          alt=""
          className="h-full object-cover"
          loading="lazy"
        />
      </button>
      <Lightbox
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={slides}
      />
    </div>
  );
};

export default App;
