import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';

const OurProjects = () => {
  const data = useStaticQuery(graphql`
    query ProjectsSectionQuery {
      Project1: file(relativePath: { eq: "project-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 528) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Project2: file(relativePath: { eq: "project-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 528) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Project3: file(relativePath: { eq: "project-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 528) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <div id="our-projects" className="max-w-6xl mx-auto px-8 py-12 w-full">
      <h2 className="font-bold leading-none mb-12 text-4xl text-pink-600 uppercase">
        Our Projects
      </h2>
      <div className="flex flex-wrap -mx-4 pb-12 md:pb-0">
        <div className="flex flex-col mb-4 md:pb-12 px-4 w-full md:w-1/2">
          <h3 className="font-light mt-4 uppercase text-pink-600 text-xl">
            Birramal Road - <span className="inline-block">New Build</span>
          </h3>
          <ul className="list-disc ml-6">
            <li>Roof</li>
            <li>
              Supply and install:
              <ul className="list-disc ml-6">
                <li>Top hat battens</li>
                <li>55mm anticon insulation blanket</li>
                <li>Colorbond 0.42bmt corrugated roof sheets in Surfmist</li>
                <li>Colorbond valleys and ridges</li>
                <li>Colorbond fascia</li>
                <li>Colorbond half round flat back and half round gutter</li>
                <li>Gable roofs with custom work</li>
                <li>Aluminum leaf guard to all gutters and valleys</li>
                <li>Downpipes pvc with stainless steel stand off brackets</li>
              </ul>
            </li>
          </ul>
          <p className="font-medium mt-auto pt-4 text-pink-600 hover:text-pink-500 hover:underline">
            <Link to="/projects/#project-1">See more projects here</Link>
          </p>
        </div>
        <div className="mb-8 relative w-full md:w-1/2">
          <div className="md:absolute md:inset-0 mx-4 md:overflow-hidden">
            <Image
              style={{ height: `100%` }}
              fluid={data.Project1.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurProjects;
