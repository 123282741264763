import React from 'react';

const CallToActionServices = () => {
  return (
    <div className="relative">
      <div className="sm:flex justify-end mt-24 relative up-50">
        <div className="absolute bg-gray-800 inset-0 my-4 w-full" />
        <p className="cta-right bg-gray-500 p-8 font-bold font-display leading-tight text-white text-xl md:text-2xl lg:text-3xl text-right uppercase">
          <span className="inline-block ml-8 sm:ml-24 whitespace-no-wrap">
            Call us for a
          </span>
          <br />
          <span className="inline-block ml-8 sm:ml-24 whitespace-no-wrap">
            {' '}
            free measure
          </span>
          <br />
          <span className="inline-block ml-8 sm:ml-24 whitespace-no-wrap">
            {' '}
            and quote
          </span>
        </p>
      </div>
    </div>
  );
};

export default CallToActionServices;
